window.$ = window.jQuery = require('jquery');
require('popper.js');
require('bootstrap');
window.ajax = require('./ajax').default;
window._ = require('lodash');
window.moment = require('moment');
window.Vue = require('vue');

Vue.prototype.__ = window.__ = require('./helpers').translate;
Vue.prototype.$eventBus = new Vue();

require('./vue/filters');
require('./vue/directives');
